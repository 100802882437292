.active li button {
    color: #10B981 !important;
    background-color: rgba(255,255,255, 0.08) !important;
    border-radius: 10px  !important;
    font-weight: bold !important;
    justify-content: flex-start !important;
    text-align: left !important;
    text-transform: none !important;
    width: 100% !important;
}

.inactive li button {
    color: #ffffff !important;
    border-radius: 10px  !important;
    font-weight: bold !important;
    justify-content: flex-start !important;
    text-align: left !important;
    text-transform: none !important;
    width: 100% !important;
}
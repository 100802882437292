.wrapper {
  background: white;
  display: block;
  margin: 0 auto;
  margin-bottom: 0.5cm;
  box-shadow: 0 0 0.5cm rgba(0, 0, 0, 0.5);
  width: 21cm;
  padding: 10px 100px;
  height: 29.7cm;
}
@media print {
  body,
  .wrapper {
    margin: 0;
    box-shadow: 0;
  }

    .wrapper {
        padding: 50px 40px;
    }
}

.inactivated:hover {
  background-color: #f5f5f5;
  border-radius: 10px;
  cursor: pointer;
}

.active {
  background-color: #5048e5;
  border-radius: 10px;
  color: white;
}

/* width */
::-webkit-scrollbar {
  width: 5px;
  border-radius: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;

}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
  
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
  border-radius: 10px;

}


.sendMsg {
  position: fixed;
  display: flex;
  bottom: 1%;
  width: 52%;
  z-index: 1;
  padding: 20px 10px;
  align-items: center;
  background-color: #f5f5f5;
}

.msgs {
  padding: 50px 0;
  display: flex;
  flex-direction: column;
}

.msg {
  display: flex;
  padding: 5px 15px ;
  margin: 10px 10px 40px 10px ;
  border-radius: 3000px;
  box-shadow: 0 0 10px rgb(164, 164, 164);
  align-items: center;
}

.sent {
  background-color: #5048e5;
  color: white;
  border-top-right-radius: 1000px;
  flex-direction: row-reverse;
  text-align: end;
  float: right;
}

.received {
  border: 1px solid lightgray;
  background-color: #f5f5f5;
  border-top-left-radius: 1000px;
  padding: 10px ;
  float: left;
}

.msg_text {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-weight: 500;
  font-size: 15px;
  overflow-wrap: break-word;

}